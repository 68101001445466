import axios from 'axios'

class ClientAccountService {
  index () {
    return axios.get('/api/warehouse-account/client-accounts')
  }

  get (id) {
    return axios.get('/api/warehouse-account/client-accounts/' + id)
    // return axios({
    //   method: 'get',
    //   url: '/api/warehouse-account/warehouse',
    //   data: { id: id }
    // })
  }

  create (clientAccount) {
    const data = new FormData()
    data.append('business_name', clientAccount.business_name)
    // data.append('img_temp_file_id', brand.imgTempFileId)
    return axios.post('/api/warehouse-account/client-accounts', data)
  }

  update (id, clientAccount) {
    const data = new FormData()
    data.append('_method', 'PUT')
    if (clientAccount.business_name) data.append('business_name', clientAccount.business_name)
    // data.append('imgFile', productTypeData.imgFile)
    // return Api.post('/api/warehouse-account/warehouses/' + id + '?_method=PUT', data)
    return axios({
      method: 'post',
      url: '/api/warehouse-account/client-accounts/'+id,
      data: data
    })
  }

  destroy (id) {
    return axios({
      method: 'delete',
      url: '/api/warehouse-account/client-accounts',
      data: { id: id }
    })
  }
}

export default new ClientAccountService()
