import axios from 'axios'

class WarehouseService {
  index () {
    return axios.get('/api/warehouse-account/warehouses')
  }

  get (id) {
    return axios.get('/api/warehouse-account/warehouses/' + id)
    // return axios({
    //   method: 'get',
    //   url: '/api/warehouse-account/warehouse',
    //   data: { id: id }
    // })
  }

  create (warehouse) {
    const data = new FormData()
    data.append('name', warehouse.name)
    data.append('address', warehouse.address)
    data.append('address2', warehouse.address2)
    data.append('city', warehouse.city)
    data.append('state', warehouse.state)
    data.append('zip', warehouse.zip)
    // data.append('img_temp_file_id', brand.imgTempFileId)
    return axios.post('/api/warehouse-account/warehouses', data)
  }

  update (id, warehouse) {
    const data = new FormData()
    data.append('_method', 'PUT')
    if (warehouse.name) data.append('name', warehouse.name)
    if (warehouse.name) data.append('address', warehouse.address)
    if (warehouse.name) data.append('address2', warehouse.address2)
    if (warehouse.name) data.append('city', warehouse.city)
    if (warehouse.name) data.append('state', warehouse.state)
    if (warehouse.name) data.append('zip', warehouse.zip)
    // data.append('imgFile', productTypeData.imgFile)
    // return Api.post('/api/warehouse-account/warehouses/' + id + '?_method=PUT', data)
    return axios({
      method: 'post',
      url: '/api/warehouse-account/warehouses/'+id,
      data: data
    })
  }

  destroy (id) {
    return axios({
      method: 'delete',
      url: '/api/warehouse-account/warehouses',
      data: { id: id }
    })
  }
}

export default new WarehouseService()
