<template>
  <div class="row" v-loading="isLoading">
    <div class="col-md-12">
      <card>
        <h4 slot="header" class="card-title">
          Add Warehouse
        </h4>
        <p-button type="info" size="sm" @click.prevent="goWarehouses()">Back</p-button>

        <fg-input v-model="warehouse.name" label="Name"></fg-input>
        <fg-input v-model="warehouse.address" label="Address"></fg-input>
        <fg-input v-model="warehouse.address2" label="Address 2"></fg-input>
        <fg-input v-model="warehouse.city" label="City"></fg-input>
        <fg-input v-model="warehouse.state" label="State"></fg-input>
        <fg-input v-model="warehouse.zip" label="Zip"></fg-input>

        <p-button type="info" @click.prevent="addWarehouse()">Submit</p-button>

      </card> <!-- end card -->
    </div> <!--  end col-md-6  -->
  </div>
</template>
<script>
  import { Card } from 'src/components/UIComponents'
  import { mapGetters, mapActions } from 'vuex'
  import { NotifyMixin } from 'src/mixins/notify.mixin'
  import { ErrorFromatterMixin } from 'src/mixins/errorFormatter.mixin'
  import WarehouseService from 'src/services/warehouse.service'

  export default {
    components: {
      Card
    },
    mixins: [NotifyMixin, ErrorFromatterMixin],
    computed: {
      ...mapGetters({
        user: 'auth/user'
      })
    },
    data() {
      return {
        isLoading: false,
        warehouse: {
          name: '',
          address: '',
          address2: '',
          city: '',
          state: '',
          zip: ''
        }
      }
    },
    methods: {
      ...mapActions({
        me: 'auth/me'
      }),
      goWarehouses () {
        this.$router.replace({ name: 'Warehouses' })
      },
      async addWarehouse () {
        try {
          this.loading = true
          await WarehouseService.create(this.warehouse)
          this.showNotify('Warehouse created', 'success', 'Success')
          this.loading = false
          this.goWarehouses()
        } catch (e) {
          let msg = this.errorMsgFromResponse(e.response)
          this.showNotify(msg, 'danger', 'Failed')
          this.loading = false
        }
      }
    }
  }
</script>
<style>
</style>
