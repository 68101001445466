import axios from 'axios'

class ProductService {
  index () {
    return axios.get('/api/warehouse-account/products')
  }

  get (id) {
    return axios.get('/api/warehouse-account/products/' + id)
    // return axios({
    //   method: 'get',
    //   url: '/api/warehouse-account/warehouse',
    //   data: { id: id }
    // })
  }

  create (product) {
    const data = new FormData()
    data.append('client_account_id', product.client_account_id)
    data.append('name', product.name)
    data.append('client_part_num', product.client_part_num)
    data.append('warehouse_part_num', product.warehouse_part_num)
    data.append('upc', product.upc)
    // data.append('img_temp_file_id', brand.imgTempFileId)
    return axios.post('/api/warehouse-account/products', data)
  }

  update (id, product) {
    const data = new FormData()
    data.append('_method', 'PUT')
    data.append('id', id)
    data.append('name', product.name)
    data.append('client_part_num', product.client_part_num)
    data.append('warehouse_part_num', product.warehouse_part_num)
    data.append('upc', product.upc)
    // data.append('imgFile', productTypeData.imgFile)
    // return Api.post('/api/warehouse-account/warehouses/' + id + '?_method=PUT', data)
    return axios({
      method: 'post',
      url: '/api/warehouse-account/products/'+id,
      data: data
    })
  }

  destroy (id) {
    return axios({
      method: 'delete',
      url: '/api/warehouse-account/products',
      data: { id: id }
    })
  }
}

export default new ProductService()
