import axios from 'axios'

class InboundShipmentService {
  index () {
    return axios.get('/api/client-account/inbound-shipments')
  }

  create (inboundShipment) {
    const data = new FormData()
    data.append('warehouse_id', inboundShipment.warehouse_id)
    data.append('contents', inboundShipment.contents)
    data.append('tracking_num', inboundShipment.tracking_num)
    data.append('notes', inboundShipment.notes)
    data.append('products', JSON.stringify(inboundShipment.products))
    // data.append('img_temp_file_id', brand.imgTempFileId)
    return axios.post('/api/client-account/inbound-shipments', data)
  }
}

export default new InboundShipmentService()
