<template>
  <div class="row" v-loading="isLoading">
    <div class="col-md-12">
      <card>
        <h4 slot="header" class="card-title">
          Edit Product
        </h4>
        <p-button type="info" size="sm" @click.prevent="goProducts()">Back</p-button>

        <fg-input v-model="product.client_account.business_name" label="Client" disabled></fg-input>
        <fg-input v-model="product.name" label="Name"></fg-input>
        <fg-input v-model="product.client_part_num" label="Client Part Number"></fg-input>
        <fg-input v-model="product.warehouse_part_num" label="Warehouse Part Number"></fg-input>
        <fg-input v-model="product.upc" label="UPC"></fg-input>

        <p-button type="info" @click.prevent="editProduct()">Submit</p-button>

      </card> <!-- end card -->
    </div> <!--  end col-md-6  -->
  </div>
</template>
<script>
  import { Card } from 'src/components/UIComponents'
  import { mapGetters, mapActions } from 'vuex'
  import { NotifyMixin } from 'src/mixins/notify.mixin'
  import { ErrorFromatterMixin } from 'src/mixins/errorFormatter.mixin'
  import ProductService from 'src/services/warehouseAccount/product.service'

  export default {
    components: {
      Card
    },
    mixins: [NotifyMixin, ErrorFromatterMixin],
    computed: {
      ...mapGetters({
        user: 'auth/user'
      })
    },
    data() {
      return {
        isLoading: true,
        product: {
          id: '',
          name: '',
          client_part_num: '',
          warehouse_part_num: '',
          upc: '',
          client_account: {
            business_name: ''
          }
        }
      }
    },
    methods: {
      ...mapActions({
        me: 'auth/me'
      }),
      init () {
        if (this.$route.params.id) {
          this.isLoading = true
          Promise.all([this.loadProduct(this.$route.params.id)]).then((values) => {
            this.isLoading = false
          })
        } else {
          let msg = "id param does not exist so no product to load"
          this.showNotify(msg, 'danger', 'Failed')
          this.goProducts()
        }
      },
      async loadProduct (id) {
        try {
          let response = await ProductService.get(id)
          this.product = response.data
          this.product.client_account_id = this.product.account_id
        } catch (e) {
          let msg = this.errorMsgFromResponse(e.response)
          this.showNotify(msg, 'danger', 'Failed')
          this.goProducts()
        }
      },
      goProducts () {
        this.$router.replace({ name: 'Products' })
      },
      async editProduct () {
        try {
          this.isLoading = true
          await ProductService.update(this.product.id, this.product)
          this.showNotify('Product updated', 'success', 'Success')
          this.isLoading = false
          this.goProducts()
        } catch (e) {
          let msg = this.errorMsgFromResponse(e.response)
          this.showNotify(msg, 'danger', 'Failed')
          this.isLoading = false
        }
      }
    },
    mounted: function () {
      this.$nextTick(function () {
        this.init()
      })
    }
  }
</script>
<style>
</style>
