<template>
  <div class="row" v-loading="isLoading">
    <div class="col-md-12">
      <card>
        <h4 slot="header" class="card-title">
          Add Product
        </h4>
        <p-button type="info" size="sm" @click.prevent="goProducts()">Back</p-button>

        <fg-input>
          <el-select class="select-info"
                      size="large"
                      placeholder="Client"
                      v-model="product.client_account_id">
            <el-option v-for="option in clientAccounts"
                        class="select-info"
                        :value="option.id"
                        :label="option.business_name"
                        :key="option.id">
            </el-option>
          </el-select>
        </fg-input>
        <fg-input v-model="product.name" label="Name"></fg-input>
        <fg-input v-model="product.client_part_num" label="Client Part Number"></fg-input>
        <fg-input v-model="product.warehouse_part_num" label="Warehouse Part Number"></fg-input>
        <fg-input v-model="product.upc" label="UPC"></fg-input>

        <p-button type="info" @click.prevent="addProduct()">Submit</p-button>

      </card> <!-- end card -->
    </div> <!--  end col-md-6  -->
  </div>
</template>
<script>
  import { Card } from 'src/components/UIComponents'
  import { Select, Option } from 'element-ui'
  import { mapGetters, mapActions } from 'vuex'
  import { NotifyMixin } from 'src/mixins/notify.mixin'
  import { ErrorFromatterMixin } from 'src/mixins/errorFormatter.mixin'
  import ClientAccountService from 'src/services/clientAccount.service'
  import ProductService from 'src/services/warehouseAccount/product.service'

  export default {
    components: {
      Card,
      [Select.name]: Select,
      [Option.name]: Option
    },
    mixins: [NotifyMixin, ErrorFromatterMixin],
    computed: {
      ...mapGetters({
        user: 'auth/user'
      })
    },
    data() {
      return {
        isLoading: true,
        clientAccounts: [],
        product: {
          client_account_id: null,
          name: '',
          client_part_num: '',
          warehouse_part_num: '',
          upc: ''
        }
      }
    },
    methods: {
      ...mapActions({
        me: 'auth/me'
      }),
      init () {
        this.isLoading = true
        this.loadClientAccounts()
      },
      async loadClientAccounts () {
        let response = await ClientAccountService.index()
        this.clientAccounts = response.data
        this.isLoading = false
      },
      goProducts () {
        this.$router.replace({ name: 'Products' })
      },
      async addProduct () {
        try {
          this.isLoading = true
          await ProductService.create(this.product)
          this.showNotify('Product created', 'success', 'Success')
          this.isLoading = false
          this.goProducts()
        } catch (e) {
          let msg = this.errorMsgFromResponse(e.response)
          this.showNotify(msg, 'danger', 'Failed')
          this.isLoading = false
        }
      }
    },
    mounted: function () {
      this.$nextTick(function () {
        this.init()
      })
    }
  }
</script>
<style>
</style>
